const baseurl = {
	stage1baseurl: "https://stage.diro.live/Zuul-1.0/",
	stage2baseurl: "https://stage2.diro.live/Zuul-1.0/",
	prodbaseurl: "https://prod.dirolabs.com/Zuul-1.0/",
  stage1nodeurl:"https://api1.diro.live/",
  stage2nodeurl:"https://api2.diro.live/",
  prodnodeurl:"https://api.diro.io/"
};
const stage2 = {
  "getpdftemplate":baseurl.stage2baseurl+"IdMaster-2.0/link/getpdftemplate",
  //"getpdftemplate":"http://localhost:8449/link/getpdftemplate",
  "pdftojson":"https://api.dirolabs.com/beta/pdf-to-json",
  "pdftojsonv3":baseurl.stage2baseurl+"HI-2.0/pdf/pdftojson",
    //"pdftojson":"http://localhost:8442/pdftojson",
    "testjson":baseurl.stage2baseurl+"HI-2.0/testjson",
    //"testjson":"http://localhost:8442/testjson",
    "updatepdftemplate":baseurl.stage2baseurl+"IdMaster-2.0/link/updatepdftemplate",
    //"updatepdftemplate":"http://localhost:8449/link/updatepdftemplate",
    "getautopdftojson":baseurl.stage2baseurl+"HI-2.0/getautopdftojson",
    //"getautopdftojson":"http://localhost:8442/getautopdftojson",
   "getquestionanswer":"https://api2.diro.live/textract",
   //"getlivequestionanswer":"https://api2.diro.live/textract/live-extract",Deprecated
   "deletetemplate":" https://api2.diro.live/textract/delete-template",
  //  "deletetemplate":"https://api2.diro.live/textract/delete-template",
   //"getquestionanswer":"http://localhost:3000/textract",
   //"getlivequestionanswer":"http://localhost:3000/textract/live-extract",
   //"deletetemplate":"http://localhost:3000/textract/delete-template",
   "login": baseurl.stage2baseurl+"organization-2.0/login",
   "gethiquestion":"https://api2.diro.live/textract/gethiquestionsforawstextract",
   "gethiquestionchatgpt":"https://api2.diro.live/textract/gethiquestionsforchatgpt",
   //"gethiquestion":"http://localhost:3000/textract/gethiquestions",
   "translatepdf":baseurl.stage2baseurl+"organization-2.0/translatepdf",
   "getquestionmodalanswer":"https://api2.diro.live/textract/gethiquestionsmodalanswer",
   //"getquestionmodalanswer":"http://localhost:3000/textract/gethiquestionsmodalanswer",
   "sessionstats": baseurl.stage2baseurl + "User-2.0/getlastclickedlink",
   "getpendinglink":baseurl.stage2baseurl +"IdMaster-2.0/link/getpendinglink",
   //"getpendinglink":"http://localhost:8449/link/getpendinglink",
   "getcountrydata":baseurl.stage2baseurl +"VerifiedCountryDetail-2.0/getVerifiedCountry",
   "updatelink":baseurl.stage2baseurl +"IdMaster-2.0/link/update",
   "getChatGptResponse":"https://api2.diro.live/textract/chatgpttextract",
   //"getChatGptResponse":"http://localhost:3000/textract/chatgpttextract",
   // "sentryDSN":"https://f4600ada2b454fca9d558f403b3156ff@o1042621.ingest.sentry.io/4505401963511808",
   "downloadPrivacy":true,
   "updateHiAutoData":"https://api2.diro.live/textract/updateHiAutoData",
   //"updateHiAutoData":"http://localhost:3000/textract/updateHiAutoData",
   "getquestionmodalanswerfromchatgpt":"https://api2.diro.live/textract/gethiquestionsmodalanswerbychatgpt",
   "getProcessedDataFromChatGpt":"https://api2.diro.live/textract/getProcessedDataFromChatGpt",
  // "getProcessedDataFromChatGpt":"http://localhost:3000/textract/getProcessedDataFromChatGpt"
  "getUnprocessedSession":"https://api2.diro.live/textract/getUnprocessedSession",
  // "callbackforbaddoc":"https://api2.diro.live/textract/callbackforbaddoc",
  "callbackforbaddoc":"https://api2.diro.live/textract/callbackforbaddoc",


  "AuthenticationForLogin":["suyash@diro.io","dhruv@diro.io","monica@diro.io","deepak@diro.io","mamta@diro.io","gagandeep@diro.io","prasuk@diro.io","faiz@diro.io","geetu@diro.io","rishabh@diro.io","aman@diro.io","shubham@diro.io","pradyumna@diro.io","priyankayadav@diro.io","hemant@diro.io","yogesh@diro.io","akshar@diro.io","nitish@diro.io","chetan@diro.io","dsingh@diro.io","kumar@diro.io"],
  "AuthenticationForDownloadOfPdf":["suyash@diro.io","dhruv@diro.io","monica@diro.io","deepak@diro.io","mamta@diro.io","gagandeep@diro.io","prasuk@diro.io","faiz@diro.io","geetu@diro.io"],
  "graylogurl":"https://utils.diro.live/logs",
  "AscessForAcknowledgementUncheck":["suyash@diro.io","dhruv@diro.io","monica@diro.io","deepak@diro.io"],
  "AsignTo":["deepak@diro.io", "shubham@diro.io","aman@diro.io"],
   //fusionauth
   twoFactorLogin:baseurl.stage2baseurl + "organization-2.0/twoFactorLogin",
   sendLoginOtp:baseurl.stage1nodeurl + "org/api/sendLoginOtp",
   qrCode:baseurl.stage2nodeurl + "org/api/generateQR",
   sendOtp:baseurl.stage2nodeurl + "org/api/sendOTP",
   enableTwoFactor:baseurl.stage1nodeurl + "org/api/enabletwofactor",
}


const stage1 = {
    "download":baseurl.stage1baseurl+"uploadKyc-2.0/downloadDoucment",
    "getpdftemplate":baseurl.stage1baseurl+"IdMaster-2.0/link/getpdftemplate",
    "pdftojson":baseurl.stage1baseurl+"HI-2.0/pdftojson",
    "pdftojsonv3":baseurl.stage1baseurl+"HI-2.0/pdf/pdftojson",
    "testjson":baseurl.stage1baseurl+"uploadKyc-2.0/testjson",
    "updatepdftemplate":baseurl.stage1baseurl+"IdMaster-2.0/link/updatepdftemplate",
    "getautopdftojson":baseurl.stage1baseurl+"HI-2.0/getautopdftojson",
    "getquestionanswer":"https://api1.diro.me.live/textract",
    //"getlivequestionanswer":"https://textract1.diro.live/textract/live-extract",Deprecated
    "deletetemplate":"https://api1.diro.live/textract/delete-template",
    "login": baseurl.stage1baseurl+"organization-2.0/login",
    "gethiquestion":"https://api1.diro.live/textract/gethiquestions",
    "gethiquestionchatgpt":"https://api1.diro.live/textract/gethiquestionsforchatgpt",
    "translatepdf":baseurl.stage1baseurl+"organization-2.0/translatepdf",
    "sessionstats": baseurl.stage1baseurl + "User-2.0/getlastclickedlink",
    "getpendinglink":baseurl.stage1baseurl +"IdMaster-2.0/link/getpendinglink",
    "getcountrydata":baseurl.stage1baseurl +"VerifiedCountryDetail-2.0/getVerifiedCountry",
    "updatelink":baseurl.stage1baseurl +"IdMaster-2.0/link/update",
    "getChatGptResponse":"https://api1.diro.live/textract/chatgpttextract",
    "sentryDSN":"https://730de566c4d04635bf47fdb0228b46d9@o4505041099030528.ingest.sentry.io/4505401951911936",
    "downloadPrivacy":false,
    "updateHiAutoData":"https://api1.diro.live/textract/updateHiAutoData",
    "getquestionmodalanswerfromchatgpt":"https://api1.diro.live/textract/gethiquestionsmodalanswerbychatgpt",
    "getProcessedDataFromChatGpt":"https://api1.diro.live/textract/getProcessedDataFromChatGpt",
    "callbackforbaddoc":"https://api1.diro.live/textract/callbackforbaddoc",
    "getUnprocessedSession":"https://api1.diro.live/textract/getUnprocessedSession",
    "AuthenticationForLogin":["suyash@diro.io","dhruv@diro.io","monica@diro.io","deepak@diro.io","mamta@diro.io","gagandeep@diro.io","prasuk@diro.io","hemant@diro.io","geetu@diro.io","chetan@diro.io","akshar@diro.io", "rishabh@diro.io","pradyumna@diro.io","dsingh@diro.io","kumar@diro.io"],
    "AuthenticationForDownloadOfPdf":["suyash@diro.io","dhruv@diro.io","monica@diro.io","deepak@diro.io","mamta@diro.io","gagandeep@diro.io","prasuk@diro.io"],
    "graylogurl":"https://api1.diro.live/logs/logs",
    "AscessForAcknowledgementUncheck":["suyash@diro.io","dhruv@diro.io","monica@diro.io","deepak@diro.io"],   
    "AsignTo":["deepak@diro.io", "shubham@diro.io","aman@diro.io"],
    //fusionauth
    twoFactorLogin:baseurl.stage1baseurl + "organization-2.0/twoFactorLogin",
  sendLoginOtp:baseurl.stage1nodeurl + "org/api/sendLoginOtp",
  qrCode:baseurl.stage1nodeurl + "org/api/generateQR",
  sendOtp:baseurl.stage1nodeurl + "org/api/sendOTP",
  enableTwoFactor:baseurl.stage1nodeurl + "org/api/enabletwofactor",
  }

const prodeu = {
    "download":baseurl.prodbaseurl+"uploadKyc-2.0/downloadDoucment",
    "getpdftemplate":baseurl.prodbaseurl+"IdMaster-2.0/link/getpdftemplate",
    //"getpdftemplate":"http://localhost:8449/link/getpdftemplate",
    //"pdftojson":"https://prodeu.dirolabs.com:8443/Zuul-1.0/uploadKyc-2.0/pdftojson",      
    "pdftojson":"https://api.dirolabs.com/v2/pdf-to-json",
    "pdftojsonv3":baseurl.prodbaseurl+"HI-2.0/pdf/pdftojson",
    "testjson":baseurl.prodbaseurl+"uploadKyc-2.0/testjson",
    "updatepdftemplate":baseurl.prodbaseurl+"IdMaster-2.0/link/updatepdftemplate",
    "getautopdftojson":baseurl.prodbaseurl+"HI-2.0/getautopdftojson",
    "getquestionanswer":"https://api.diro.io/textract",
    //"getquestionanswer":"http://localhost:3000/textract",
    //"getlivequestionanswer":"https://api.diro.io/textract/live-extract",Deprecated
    "deletetemplate":"https://api.diro.io/textract/delete-template",
    "login":baseurl.prodbaseurl+"organization-2.0/login",
    "gethiquestion":"https://api.diro.io/textract/gethiquestions",
    "gethiquestionchatgpt":"https://api.diro.io/textract/gethiquestionsforchatgpt",
    "translatepdf":baseurl.prodbaseurl+"organization-2.0/translatepdf",
    "getquestionmodalanswer":"https://api.diro.io/textract/gethiquestionsmodalanswer",
    "sessionstats": baseurl.prodbaseurl + "User-2.0/getlastclickedlink",
    "getpendinglink":baseurl.prodbaseurl +"IdMaster-2.0/link/getpendinglink",
    "getcountrydata":baseurl.prodbaseurl +"VerifiedCountryDetail-2.0/getVerifiedCountry",
    "updatelink":baseurl.prodbaseurl +"IdMaster-2.0/link/update",
    "getChatGptResponse":"https://api.diro.io/textract/chatgpttextract",
    "sentryDSN":"https://c4a742a6dccf48f8925639ae58a78320@o305199.ingest.sentry.io/4505401966985216",
    // "sentryDSN":"https://730de566c4d04635bf47fdb0228b46d9@o4505041099030528.ingest.sentry.io/4505401951911936",
    "downloadPrivacy":true,
    "updateHiAutoData":"https://api.diro.io/textract/updateHiAutoData",
    "getquestionmodalanswerfromchatgpt":"https://api.diro.io/textract/gethiquestionsmodalanswerbychatgpt",
    "getProcessedDataFromChatGpt":"https://api.diro.io/textract/getProcessedDataFromChatGpt",
    "getUnprocessedSession":"https://api.diro.io/textract/getUnprocessedSession",

    "AuthenticationForLogin":["suyash@diro.io","dhruv@diro.io","monica@diro.io","deepak@diro.io","shubham@diro.io", "aman@diro.io","hemant@diro.io","geetu@diro.io","dsingh@diro.io","kumar@diro.io"],
    "AuthenticationForDownloadOfPdf":["dhruv@diro.io","deepak@diro.io"],
    "graylogurl":"https://logs.diro.live/logs",
    "AscessForAcknowledgementUncheck":["dhruv@diro.io","deepak@diro.io"],
    "AsignTo":["deepak@diro.io", "shubham@diro.io","aman@diro.io"]  ,
     //fusionauth
   twoFactorLogin:baseurl.prodbaseurl + "organization-2.0/twoFactorLogin",
   sendLoginOtp:baseurl.prodnodeurl + "org/api/sendLoginOtp",
   qrCode:baseurl.prodnodeurl + "org/api/generateQR",
   sendOtp:baseurl.prodnodeurl + "org/api/sendOTP",
   enableTwoFactor:baseurl.prodnodeurl + "org/api/enabletwofactor", 
  }
         

export { stage1  };
export { prodeu as env };
export { stage2 };
